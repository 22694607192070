import {lang, LanguageSelector} from "../language-selector";
import { QuickAccess } from "../../download/quick-access";
import {ENavLanguages, JCommonMetas, JDocProductMetas, JDownloadProductMetas, JProductInfos, L10nLabels} from "../api";
import * as productInfosProvider from "../product-infos";
import {labels as commonLabels, sc$} from "../commons";
import { injectHtmlFrag } from "../../download/commons";

export {init, LanguageSelector, QuickAccess }

const labels:L10nLabels = {
	[ENavLanguages.fr]:{
		//500
		"titleError":"Une erreur est survenue",
		"messageError":(code, ut)=>`<div>
			<p>Le serveur a rencontré une erreur. Veuillez tenter ultérieurement.</p>
			<p>Si le problème persiste, n'hésitez pas à nous le <a href="https://forums.scenari.org/c/sitessc/51">signaler sur les forums</a>.</p>
			${code != "example" ? "<p><a href='"+ut+"'>Retour à l'accueil.</a></p>":""}
		</div>`,

		//403
		"titleForbidden": "Erreur 403 - Accès interdit",
		"messageForbidden":(ut)=>`<div><p>La page que vous avez demandé n'est pas accessible.</p><p><a href="${ut}">Retour à l'accueil.</a></p></div>`,

		//404
		"title404":"Erreur 404 - Page non trouvée",
		//passage en fonction pour récuếrer l'ut initialisé.
		"message404":(ut)=>`<div><p>La page que vous avez demandé n'existe pas.</p><p><a href="${ut}">Retour à l'accueil.</a></p></div>`,

		
		//Messages spécifiques
		//403 dans un dossier produit Download
		"MessageDownloadProductForbidden":(product:JProductInfos<JDownloadProductMetas, any>)=>`<div>
			<p>Le fichier de téléchargement '${window.location.pathname.split("/").pop()}' que vous avez demandé n'est pas accessible.</p>
			<p><a href="${urltree+product.path}">Retour à la page ${product.metas.downloadLocInfos[lang]?.title||product.metas.productKey} ${product.metas.majorVersion}.</a></p>
		</div>`,
		//403 dans un dossier Doc
		"MessageDocProductForbidden":(product:JProductInfos<JDocProductMetas, any>)=>`<div>
			<p>La page que vous avez demandé n'est pas accessible.</p>
			<p><a href="${urltree+product.path}">Retour à la page ${product.metas.docLocInfos[lang]?.title||product.metas.productKey} ${product.metas.majorVersion}.</a></p>
		</div>`,
		//404 dans un dossier produit Download
		"titleDownloadProduct404":"Erreur 404 - Fichier de téléchargement non trouvé",
		"messageDownloadProduct404":(product:JProductInfos<JDownloadProductMetas, any>)=>`<div>
			<p>Le fichier de téléchargement '${window.location.pathname.split("/").pop()}' que vous avez demandé n'existe pas.</p>
			<p><a href="${urltree+product.path}">Retour à la page ${product.metas.downloadLocInfos[lang]?.title||product.metas.productKey} ${product.metas.majorVersion}.</a></p>
		</div>`,
		//404 dans un dossier Doc
		"MessageDocProduct404":(product:JProductInfos<JDocProductMetas, any>)=>`<div>
			<p>La page que vous avez demandé n'existe pas.</p>
			<p><a href="${urltree+product.path}">Retour à la page ${product.metas.docLocInfos[lang]?.title||product.metas.productKey} ${product.metas.majorVersion}.</a></p>
		</div>`,
	},
	[ENavLanguages.en]:{
		//500
		"titleError":"An error has occurred",
		"messageError":(code, ut)=>`<div>
			<p>The server has encountered an error. Please try again later.</p>
			<p>If the problem persists, don't hesitate to <a href=“https://forums.scenari.org/c/sitessc/51”>report it on the forums</a>.</p>
			${code != "example" ? "<p><a href='"+ut+"'>Back to home.</a></p>":""}
		</div>`,

		//403
		"titleForbidden": "Error 403 - Access forbidden",
		"messageForbidden":(ut)=>`<div><p>The requested page is not available.</p><p><a href="${ut}">Back to home.</a></p></div>`,

		//404
		"title404":"Error 404 - Page not found",
		"message404":(ut)=>`<div><p>The requested page does not exist.</p><p><a href="${ut}">Back to home.</a></p></div>`,


		//Messages spécifiques
		//403 dans un dossier produit Download
		"MessageDownloadProductForbidden":(product:JProductInfos<JDownloadProductMetas, any>)=>`<div>
			<p>The requested download file '${window.location.pathname.split("/").pop()}' is not available.</p>
			<p><a href="${urltree+product.path}">Back to the ${product.metas.downloadLocInfos[lang]?.title||product.metas.productKey} ${product.metas.majorVersion} page.</a></p>
		</div>`,
		//403 dans un dossier Doc
		"MessageDocProductForbidden":(product:JProductInfos<JDocProductMetas, any>)=>`<div>
			<p>The requested page is not available.</p>
			<p><a href="${urltree+product.path}">Back to the ${product.metas.docLocInfos[lang]?.title||product.metas.productKey} ${product.metas.majorVersion} page.</a></p>
		</div>`,
		"titleDownloadProduct404":"Error 404 - Download file not found",
		"messageDownloadProduct404":(product:JProductInfos<JDownloadProductMetas, any>, filename)=>`<div>
			<p>The requested download file '${window.location.pathname.split("/").pop()}' does not exist.</p>
			<p><a href="${urltree+product.path}">Back to ${product.metas.downloadLocInfos[lang]?.title||product.metas.productKey} ${product.metas.majorVersion} page.</a></p>
		</div>`,
		//404 dans un dossier Doc
		"MessageDocProduct404":(product:JProductInfos<JDocProductMetas, any>)=>`<div>
			<p>The requested page does not exist.</p>
			<p><a href="${urltree+product.path}">Back to the ${product.metas.docLocInfos[lang]?.title||product.metas.productKey} ${product.metas.majorVersion} page.</a></p>
		</div>`
	}
}

let code:string
let urltree:string
let es:string
let productInfos:JProductInfos<JCommonMetas, any>
let parentProductInfos:JProductInfos<JCommonMetas, any> = null

const error = document.querySelector("meta[itemprop='error']").getAttribute("content")
const title = sc$("title")
const message = sc$("message")
const path = error != "500" ? document.querySelector("meta[itemprop='path']").getAttribute("content") : null

/**
 * Init général.
 */
async function init(portlet:string, ut:string, search:string) {
	urltree = ut
	es = search
	code = portlet

	//Language Selector
	const ls = document.querySelector("language-selector") as LanguageSelector
	ls.addEventListener("language-change", rebuild)
	ls.disableRedirect()

	if(error == "500") init500()
	else switch (code){
		case "download": await initDownloadError(); break;
		case "doc": await initDocError(); break;
		case "root": initRootError(); break;
		case "example": initExampleError; break;
	}
	rebuild()
}

function rebuild(){
	if(error == "500") rebuild500();
	else switch (code) {
		case "download": rebuildDownloadError(); break;
		case "doc": rebuildDocError(); break;
		case "root": rebuildRootError(); break;
		case "example": rebuildExampleError(); break;
	}
	document.querySelector("title").innerText = title.innerText
}


//500
function init500(){
	const ls = document.querySelector("language-selector") as LanguageSelector
	ls.addEventListener("language-change", rebuild)
}
function rebuild500(){
	title.innerText = s("titleError")
	message.innerHTML=  s("messageError", code, urltree)
}

// Download
async function initDownloadError(){
	//Header
	injectHtmlFrag(urltree)
	//Quick access
	const qa = (document.querySelector("quick-access") as QuickAccess);
	qa.init(urltree, es).then()
	qa.rebuild()
	//Language Selector
	const ls = document.querySelector("language-selector") as LanguageSelector
	ls.addEventListener("language-change", (event)=>injectHtmlFrag(urltree))

	//Fetch pi
	productInfos = await fetchParentProductInfos(path)
}

function rebuildDownloadError(){
	const qa = (document.querySelector("quick-access") as QuickAccess);
	qa.rebuild();

	if(productInfos.metas.processing == "dwnldProduct"){
		title.innerText = error == "404" ? s("titleDownloadProduct404") : s("titleForbidden")
		message.innerHTML=  error == "404" ? s("messageDownloadProduct404", productInfos) : s("MessageDownloadProductForbidden", productInfos)
	}
	else{
		title.innerText = error == "404" ? s("title404") : s("titleForbidden")
		message.innerHTML=  error == "404" ? s("message404", urltree) : s("messageForbidden", urltree)
	}
}

// Doc
async function initDocError(){
	//Header
	injectHtmlFrag(urltree)
	//Language Selector
	const ls = document.querySelector("language-selector") as LanguageSelector
	ls.addEventListener("language-change", (event)=>injectHtmlFrag(urltree))

	//Fetch pi
	productInfos = await fetchParentProductInfos(path)
	if(["locDoc", "archive", "locResDokiel", "locResType", "archiveDokiel"].indexOf(productInfos.metas.processing) != -1) parentProductInfos = await fetchParentProductInfos(productInfos.path.substring(0, productInfos.path.length-1))
}
function rebuildDocError(){
	let foundPi = null;
	if(productInfos.metas.processing == "docProduct") foundPi = productInfos
	if(parentProductInfos?.metas?.processing == "docProduct") foundPi = parentProductInfos

	title.innerText = error == "404" ? s("title404") : s("titleForbidden")
	if(foundPi?.metas.processing == "docProduct") message.innerHTML=  error == "404" ? s("MessageDocProduct404", foundPi) : s("messageForbidden", urltree)
	else message.innerHTML=  error == "404" ? s("message404", urltree) : s("messageForbidden", urltree)
}

//Root
function initRootError(){}
function rebuildRootError(){
	title.innerText = error == "404" ? s("title404") : s("titleForbidden")
	message.innerHTML=  error == "404" ? s("message404", urltree) : s("messageForbidden", urltree)
}
function initExampleError(){}
function rebuildExampleError(){
	rebuildRootError();
}


async function fetchParentProductInfos(path:string){
	let parent_path = path.substring(0, path.lastIndexOf("/"))
	productInfosProvider.init(urltree)
	return  await productInfosProvider.getProductInfos(parent_path);
}

function s(key:string, ...args:any):string{
	if(key in commonLabels){
		if(typeof commonLabels[lang][key] == "function") return (commonLabels[lang][key] as (...args:any)=>string)(...args)
		else return commonLabels[lang][key] as string
	}

	if(typeof labels[lang][key] == "function") return (labels[lang][key] as (...args:any)=>string)(...args)
	else return labels[lang][key] as string
}