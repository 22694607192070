import {ENavLanguages, EStage, JDownloadProductMetas, JDownloadProductSource, L10nLabels} from "../commons/api";
import {ScSoftwareComponent} from "../commons/language-selector";
import {formatDate} from "../commons/commons";
import {JSX} from "wfr/commons/jsx"

export {ProductTile, ETileTitle}

interface JTileConf{
	title:ETileTitle,
	fullDatedVersion?:boolean,
	langs?:string[]
	tagline?:boolean,
	addClassName?:string,
	fromMinorVersion?:string,
	noLogo?:boolean,
}

enum ETileTitle{
	label="label", // Label
	short="short", // Label 2 stage
	long="long", 	 // Label 2.23 stage
	dep="dep" // Label 2(.23 et +)
}


const labels:L10nLabels = {
	[ENavLanguages.fr]:{
		andMore:minor=>`(.${minor} et +)`
	},
	[ENavLanguages.en]:{
		andMore:minor=>`(.${minor} and more)`
	},
}

class ProductTile extends ScSoftwareComponent {
	protected productMetas:JDownloadProductSource|JDownloadProductMetas
	protected urlTree:string
	protected labels = labels
	protected conf:JTileConf

	constructor(productMetas:JDownloadProductSource|JDownloadProductMetas, ut:string, conf:JTileConf) {
		super()
		this.productMetas = productMetas
		this.urlTree = ut
		this.conf = conf
	}

	async connectedCallback(): Promise<void> {
		await super.connectedCallback();
		this.rebuild();
	}

	rebuild(): boolean {
		if (!super.rebuild()) return false;
		this.shadowRoot.append(<a href={`${this.urlTree}${this.productMetas.path}`} class={`${this.productMetas.productType} ${this.productMetas.nature || ""} ${this.productMetas.extType || ""} ${this.conf.addClassName||""}`}><div>
			<div class="head">
				<div class="title">
					{this.logo()}
					{this.conf.title == ETileTitle.label ? this.label() : null}
					{this.conf.title == ETileTitle.short ? this.fullName() : null}
					{this.conf.title == ETileTitle.long ? this.fullVersionedName() : null}
					{this.conf.title == ETileTitle.dep ? this.fullDepName() : null}
				</div>
				{this.conf.fullDatedVersion? <div class="fullDatedVersion"><span class="fullVersion">v{this.productMetas.majorVersion}.{this.productMetas.minorVersion}</span><span class="releaseDate">{formatDate(this.productMetas.releaseDate)}</span></div> : null}
			</div>
			<div class="content">
				{this.conf.langs? <div class="langs">{this.conf.langs.map(lang=>{return <span class="langLabel">{this.s(`l10n_${lang}`)}</span>})}</div> : null}
				{this.conf.tagline? <div class="tagline">{this.productMetas.downloadLocInfos[this.current_lang].tagline}</div> : null}
			</div>
		</div></a>)
		return true;
	}

	click(){
		this.shadowRoot.querySelector("a").click()
	}

	logo():Element {
		return this.conf.noLogo ? null:<img class="icon" src={`${this.productMetas.docProviderUrl}/logo`} alt={this.s("altLogo")}/>
	}
	label():Element {
		return <span class="productName">{this.productMetas.downloadLocInfos[this.current_lang].title}</span>
	}
	major():Element {
		return <span class="majorVersion">{this.productMetas.majorVersion}</span>
	}
	minor():Element {
		return <span class="minorVersion">{this.productMetas.minorVersion}</span>
	}
	stage():Element{
		return this.productMetas.stage != EStage.final ? <span class={`stage ${this.productMetas.stage}`}>{this.s(this.productMetas.stage)}</span> : null
	}
	fullName():Element {
		return <span class="productFullName">{this.label()} {this.major()} {this.stage()}</span>
	}
	fullVersionedName():Element {
		return <span class="productFullVersionedName">{this.label()} {this.major()}.{this.minor()} {this.stage()}</span>
	}
	fullDepName():Element {
		return <span class="productFullVersionedName">{this.label()} {this.major()}<span class="dep">{this.s("andMore", this.conf.fromMinorVersion)}</span></span>
	}
}

customElements.define('product-tile', ProductTile)