import {ENature, ENavLanguages, EProductType, EStage, JDownloadProductSource, L10nLabels} from "../commons/api";
import {lang, ScSoftwareComponent} from "../commons/language-selector";
import {JSX} from "wfr/commons/jsx"
import {SvcESSearch} from "wfr/search/essearch";
import {JHitSource} from "wfr/search/searchapi";
import {Dict} from "wfr/commons/common";
import {ETileTitle, ProductTile} from "./product-tile";
import {filterLastKeys, sort} from "./commons"

export { QuickAccess}

const labels:L10nLabels = {
	[ENavLanguages.fr]:{
		quickAccess:"Accès rapide",
		placeHolder:"Application, serveur, extension..."
	},
	[ENavLanguages.en]:{
		quickAccess:"Quick access",
		placeHolder:"Application, server, extension..."
	},
}

interface JProductAgg{
	buckets:JPKBucket[]
}
interface JPKBucket{
	key:string,
	doc_count:number,
	majorVersion:{
		"buckets":JMJBucket[]
	}
}
interface JMJBucket{
	key:number,
	doc_count:number,
	minorVersion:{
		"hits":{
			total:{value:number, relation:string},
			hits:JHitSource<JDownloadProductSourceNorm>[]
		}
	}
}
interface JDownloadProductSourceNorm extends JDownloadProductSource{
	normLabel:string //productKey normalisé
}

class QuickAccess extends ScSoftwareComponent {
	protected esSearch:SvcESSearch<JHitSource<JDownloadProductSourceNorm>>
	protected urlTree:string
	protected labels = labels
	protected lastProducts:Dict<JDownloadProductSourceNorm> = {}
	protected productsByPk:Dict<JDownloadProductSourceNorm[]> = {}
	protected orderedLastPK:string[]

	protected filter:string

	async connectedCallback(): Promise<void> {
		super.connectedCallback();

		const keys = (event:KeyboardEvent)=>{
			const suggestDiv:HTMLDivElement = this.shadowRoot.querySelector("div.suggest")
			if(!suggestDiv) return

			const selected = suggestDiv.querySelector("li.selected")

			switch (event.code){
			case "ArrowDown":
				selected?.classList.remove("selected")
				selected?.nextElementSibling?.classList.add("selected")
				if(!selected) this.shadowRoot.querySelector("li").classList.add("selected")
				event.stopPropagation()
				event.preventDefault()
				break
			case "ArrowUp":
				selected?.classList.remove("selected")
				selected?.previousElementSibling?.classList.add("selected")
				if(!selected) this.shadowRoot.querySelector("li:last-child").classList.add("selected")
				event.stopPropagation()
				event.preventDefault()
				break
			case "Enter":
				if(selected) (selected.querySelector("product-tile") as ProductTile).click()
				event.stopPropagation()
				event.preventDefault()
				break
			case "Escape":
				suggestDiv.hidden = true
				event.stopPropagation()
				event.preventDefault()
				break
			}
		}
		window.addEventListener("keyup", keys)

		//Si un évenement click est capté sur la window, on passe la div en hidden
		window.addEventListener("click", event=>{
			const suggestDiv:HTMLDivElement = this.shadowRoot.querySelector("div.suggest")
			if(suggestDiv) suggestDiv.hidden = true
		})
		//Le custom element courant stoppe la propagation de tout event pour éviter qu'un click "interne" fasse passer la div en hidden
		this.addEventListener("click", event=>event.stopPropagation())
	}

	async init(ut:string, es:string){
		this.esSearch = new SvcESSearch(es)
		this.urlTree = ut
		const products:Dict<JDownloadProductSourceNorm> = {}
		const productAgg = (await this.esSearch.search("quickAccess")).agg.productKey as JProductAgg
		productAgg?.buckets.forEach(pkBucket=>{
			pkBucket.majorVersion.buckets.forEach(versionBucket=>{
				const productSource:JDownloadProductSourceNorm = versionBucket.minorVersion.hits.hits[0]._source as JDownloadProductSourceNorm
				try{
					productSource.normLabel = `${productSource.downloadLocInfos[lang]?.title || productSource.productKey} ${productSource.majorVersion} ${productSource.stage != EStage.final ? this.s(productSource.stage) : ""}`.toLowerCase()
					const key = `${productSource.productKey}@${productSource.majorVersion}`
					products[key] = productSource
				}
				catch (e){
					console.error(`Unable to extract normLabel from ${JSON.stringify(productSource)}`)
				}
			})
		})
		filterLastKeys(Object.keys(products), products).forEach(lastKey=> {
			this.lastProducts[lastKey] = products[lastKey];
			this.productsByPk[lastKey] = [];
			Object.keys(products).forEach(key=>{
				if(key.split("@")[0] == lastKey.split("@")[0] && key != lastKey) this.productsByPk[lastKey].push(products[key])
			});
		});
		Object.keys(this.productsByPk).forEach(key=> {
			this.productsByPk[key].sort((a,b)=> sort(a,b)*-1)
		});
		this.orderedLastPK = Object.keys(this.lastProducts).sort((a,b)=>{
			const productA = this.lastProducts[a]
			const productB = this.lastProducts[b]
			if(productA.productType != productB.productType){
				if(productA.productType == EProductType.application) return -1;
				if(productB.productType == EProductType.application) return 1;
				if(productA.productType == EProductType.server) return -1;
				if(productB.productType == EProductType.server) return -1;
				if(productA.productType == EProductType.devtool) return 1;
				if(productB.productType == EProductType.devtool) return -1;
			}
			if(productA.nature != productB.nature) return productA.nature == ENature.dedicated ? -1 : 1;

			return productA.productKey > productB.productKey ? 1 : -1;
		});
	}

	rebuild(): boolean {
		if (!super.rebuild()) return false;
		this.shadowRoot.append(<label><span>{this.s("quickAccess")}</span><input placeholder={this.s("placeHolder")}/></label>)
		const input = this.shadowRoot.querySelector("input");
		input.addEventListener("focus", event=>{
			const suggest:HTMLDivElement = this.shadowRoot.querySelector("div.suggest")
			if(suggest) suggest.hidden = false
		})
		input.addEventListener("keyup", this.inputChange);
		return true;
	}

	inputChange(event:KeyboardEvent){
		const qa = (this.getRootNode() as ShadowRoot).host as QuickAccess
		const input:HTMLInputElement = event.target as HTMLInputElement
		if(input.value.trim() == qa.filter) return;
		qa.filter = input.value.trim().toLowerCase()

		if(!qa.filter) qa.shadowRoot.querySelector("div.suggest")?.remove()
		else{
			let suggest = qa.shadowRoot.querySelector("div.suggest")
			if(!suggest) {
				qa.shadowRoot.append(<div class="suggest"></div>)
				suggest = qa.shadowRoot.querySelector("div.suggest")
			}

			suggest.innerHTML = ""
			const match = (normKey:string, filter:string)=>{
				let found = true
				filter.split(" ").forEach(token=>{if(normKey.indexOf(token) == -1) found = false});
				return found;
			}
			suggest.append(<ul>{
				qa.orderedLastPK.map(key=>{
					const children = []
					if(match(qa.lastProducts[key].normLabel, qa.filter))
						children.push(<li data-key={key}>{new ProductTile(qa.lastProducts[key], qa.urlTree, {title:ETileTitle.short, addClassName:"quickaccess"})}</li>)
					children.push(...qa.productsByPk[key].map(product=>{
						if(match(product.normLabel, qa.filter))
							return <li data-key={key}>{new ProductTile(product, qa.urlTree, {title:ETileTitle.short, addClassName:"quickaccess"})}</li>
						else return null;
					}))
					return children
				})}
			</ul>)
		}
	}
}

customElements.define('quick-access', QuickAccess)