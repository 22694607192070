declare global {
	namespace JSX {
		// On considère tout élément JSX comme un HTMLElement
		interface Element extends HTMLElement {
			[key: string]: any
		}

		interface ElementAttributesProperty {
			_JsxProps: any
		}

		interface IntrinsicElements {
			[key: string]: any
		}
	}
}

export const JSX = {

	/** API React. */
	createElement(tag: string | typeof HTMLElement | typeof ShadowJsx, attributes: { [k: string]: any }, ...children: any[]): any {
		let elt: HTMLElement
		if (typeof tag === 'string') elt = document.createElement(tag) as HTMLElement
		else if (tag === ShadowJsx) return new ShadowJsx(children, attributes)
		else elt = new (tag as typeof HTMLElement)()
		for (const name in attributes) {
			const attr = attributes[name]
			switch (typeof attr) {
			case "function":
				(elt as any)[name] = attr
				break
			case "boolean":
				if (attr === true) elt.setAttribute(name, "")
				break
			default:
				if (attr != null) elt.setAttribute(name, attr)
			}
		}
		if (tag === 'template') {
			for (const child of children) if (child != null) JSX.appendChildren((elt as HTMLTemplateElement).content, child)
		} else {
			for (const child of children) if (child != null) JSX.appendChildren(elt, child)
		}
		return elt
	},

	/** API React. */
	appendChildren(elt: Element | DocumentFragment, children: any) {
		if (Array.isArray(children)) children.forEach(ch => JSX.appendChildren(elt, ch))
		else if (children instanceof Node) elt.appendChild(children)
		else if (children != null) {
			if (children.constructor === ShadowJsx) {
				if (elt instanceof Element) {
					const sr = elt.shadowRoot || elt.attachShadow(children.attributes?.init || {mode: 'open'})
					const styleSheets = children.attributes?.styleSheets
					if (styleSheets) sr.adoptedStyleSheets.push(...styleSheets)
					JSX.appendChildren(sr, children.children)
				}
			} else elt.appendChild(document.createTextNode(children))
		}
	}
};

export class ShadowJsx {
	_JsxProps: { init?: ShadowRootInit, styleSheets?: CSSStyleSheet[] };

	constructor(public children: any[], public attributes: { [k: string]: any }) {}
}