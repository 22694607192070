import { Dict } from "wfr/commons/common";
import {EStage, JDownloadProductMetas, JDownloadProductSource} from "../commons/api";
import {sc$} from "../commons/commons";
import {lang} from "../commons/language-selector";

export const filterLastKeys = function(keys: string[], products: Dict<JDownloadProductSource>){
	const filtered:Dict<string> ={}
	keys.forEach(key=>{
		const productKey = products[key].productKey
		if(filtered[productKey]){
			const filteredProduct = products[filtered[productKey]]
			const product = products[key]

			if(parseFloat(filteredProduct.majorVersion) < parseFloat(product.majorVersion)){
				if(product.stage == EStage.final) filtered[productKey] = key
				else if(product.stage == EStage.rc && [EStage.rc, EStage.beta, EStage.alpha].indexOf(filteredProduct.stage) != -1) filtered[productKey] = key
				else if(product.stage == EStage.beta && [EStage.beta, EStage.alpha].indexOf(filteredProduct.stage) != -1) filtered[productKey] = key
				else if(product.stage == EStage.alpha && [EStage.alpha].indexOf(filteredProduct.stage) != -1) filtered[productKey] = key
			}
		}
		else filtered[productKey] = key;
	});
	return Object.values(filtered);
}

export const sort = function(a:JDownloadProductSource|JDownloadProductMetas, b:JDownloadProductSource|JDownloadProductMetas){
	if(a.productKey != b.productKey) return a.productKey > b.productKey ? 1 : -1
	if(a.majorVersion != b.majorVersion) return parseFloat(a.majorVersion) > parseFloat(b.majorVersion) ? 1 : -1
	if(a.minorVersion != b.minorVersion){
		const aMinorVersion = a.minorVersion.split(".")
		const bMinorVersion = b.minorVersion.split(".")
		for(let i = 0 ; i < Math.min(aMinorVersion.length, bMinorVersion.length) ; i++){
			if(aMinorVersion[i] != bMinorVersion[i]) return parseInt(aMinorVersion[i]) > parseInt(bMinorVersion[i]) ? 1: -1;
		}
		return aMinorVersion.length > bMinorVersion.length ? 1 : -1;
	}
	return 1
}

export function injectHtmlFrag(urltree:string) {
	const header = sc$("scSoftHeader")
	const footer = sc$("scSoftFooter")
	fetch(`${urltree}/${lang}/~inject/header.html`)
		.then(resp=>resp.text())
		.then(text => header ? header.innerHTML = text : document.body.insertAdjacentHTML("afterbegin", text))
	fetch(`${urltree}/${lang}/~inject/footer.html`)
		.then(resp=>resp.text())
		.then(text => footer ? footer.innerHTML = text : document.body.insertAdjacentHTML("beforeend", text))
}