import {Dict} from "wfr/commons/common"

/**
 * Listes des langues de navigation supportées
 */
export enum ENavLanguages{
	fr="fr", en="en"
}

/**
 * Dict de labels
 */
export type L10nLabels = {
	[key in ENavLanguages]: Dict<((...args:any)=>string)|string>
}


/**
 * Dict localisé de locInfos (pour panel, group, olProduct et message)
 */
export type JLocInfos = {
	[key in ENavLanguages]: JLocInfo
}

/**
 * Infos localisée (pour panel, group, olProduct et message)
 */
export interface JLocInfo {
	title?:string
	body?:string
}

/**
 * Dépendance d'un product
 */
export interface JDownloadDep {
	firstMinorVersion:number,
	product:string
}

/**
 * Structure d'une page de téléchargements
 */
export interface JProductStructure {
	panels:JProductPanel[]
	groups: JProductGroup[]
}

/**
 * Panel de téléchargement
 */
export interface JProductPanel {
	code:string
	locInfos:JLocInfos,
	groups:JProductGroup[]
}

/**
 * Groupe de téléchargement
 */
export interface JProductGroup {
	locInfos:JLocInfos
	code:string
	iconClass:string
	regexps:string[]
	olProducts?:string[]
	grpOthers?:boolean
	collapsed?:boolean
}

/**
 * Référence d'un produit
 */
export interface JProductRef {
	majorVersion:string
	minorVersion:string
	path:string
	productKey:string
}

/**
 * Métas génériques du depot
 */
export interface JDepotMetas {
	deployedBy:string
	deployDate:number
	path:string
	processing:string

	updateDate?:number
	updatedBy?:string
	unlisted?:string
	olderResId?:string
}

/**
* Liste des productTypes
*/
export enum EProductType{
	application="application", extension="extension", server="server", devtool="devtool"
}

/**
 * Liste des extTypes
 */
export enum EExtType{
	skin="skin", gen="gen", mod="mod"
}

export enum ENature{
	generic="generic", dedicated="dedicated"
}

/**
 * Métas communes doc/download
 */
export interface JCommonMetas extends JDepotMetas {
	majorVersion:string
	productKey:string
	deprecated:boolean
	status:"product"|"project"|"lab"
	nature?:ENature
	productType:EProductType
	extType?:EExtType
}

/**
 * Métas spécifiques de doc
 */
export interface JDocProductMetas extends JCommonMetas {
	downloadUrl:string
	publisher:JOrganization
	contributors?:JOrganization[]
	supportDuration:number
	docLocInfos:JDocLocInfos
	esDocProductCustomId:string
}
/**
 * Organisation (pour meta éditor/contributors)
 */
export interface JOrganization{
	name:string
	url:string
}
/**
 * Dict localisé de documentations
 */
export type JDocLocInfos = {
	[key in ENavLanguages]: JDocLocInfo
}
/**
 * Infos d'une documentation localisée
 */
export interface JDocLocInfo extends JLocInfo{
	title:string //title obligatoire
	tagline?:string
	license?:string
	state: "draft"|"valid";
}


export type JDownloadLocInfos = {
	[key in ENavLanguages]: JDownloadLocInfo
}
/**
 * loc infos stockées dans Doc
 */
export interface JDownloadLocInfo extends JLocInfo{
	title:string //title obligatoire
	body:null    //body absent
	tagline?:string
}

/**
 * Métas spécifiques de download
 */
export interface JDownloadProductMetas extends JCommonMetas {
	minorVersion:string
	releaseDate:string
	stage:EStage,
	releaseNote?:string
	structure?:JProductStructure
	deps:JDownloadDep[]
	family:JProductRef[]
	downloadLocInfos:JDownloadLocInfos
	docProviderUrl:string
	esProductId:string
}

/**
 * stage d'un produit à télécharger
 */
export enum EStage {
	final="final", rc="rc", beta="beta", alpha="alpha"
}

/**
 * Infos génériques remontées par les APIs nodeInfos du depot
 */
export interface JInfos {
	n:string
	v:string
	resId:string
	path:string
	publicUrl:string
	unlisted?:boolean
}
/**
 * Fields génériques d'un productInfos
 * M -> Metas JDownloadProductMetas ou JDocProductMetas
 * C -> child, JDlInfos ou JInfos
 */
export interface JProductInfos<M,C> extends JInfos{
	ch:C[]
	hist:JProductInfosHist<M>[]
	metas:M
	succ:JProductInfosSucc<M>[]
}
/**
 * Histo d'un ProductInfos
 */
export interface JProductInfosHist<M> {
	v:string,
	unlisted?:boolean
	metas:M
}

/**
 * Successeurs d'un productInfos
 */
export interface JProductInfosSucc<M> extends JProductInfosHist<M> {
	succ:JProductInfosSucc<M>[]
}
/**
 * structure d'un lien de téléchargement
 */
export interface JDlInfos extends JInfos{
	prc:"olProduct"|"dlProduct"
	s:number
	metas:JDlMetas
}
/**
 * Métas d'un ol/dl Product (lien de téléchargement)
 */
export interface JDlMetas extends JDepotMetas{
	lang:string
	os:string
	locInfos?:JLocInfos
	exclude?:boolean
	esParentId:string
}

export interface JDownloadProductSource {
	productKey:string
	majorVersion:string
	minorVersion:string
	stage:EStage
	productType:EProductType
	extType?:EExtType
	nature?:ENature
	releaseDate:string
	path:string,
	docProviderUrl:string
	downloadLocInfos:JDownloadLocInfos
}