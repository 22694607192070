import {JProductInfos, JDownloadProductMetas, JDocProductMetas} from "./api";

export {init, getProductInfos, getDocProductInfos}

let urltree = ""

function init(ut:string){urltree = ut;}

async function get(url: string):Promise<JProductInfos<any, any>>{
	let normUrl:string = url;
	if(! normUrl.endsWith("/")) normUrl += "/"
	normUrl += "?productInfos";

	const resp = await fetch(normUrl);
	return await resp.json()
}


async function getProductInfos(path: string){
	return get(`${urltree}${path}`)
}

 async function getDocProductInfos(metas:JDownloadProductMetas):Promise<JProductInfos<JDocProductMetas, any>>{
		return get(metas.docProviderUrl)
 }