import { Dict } from "./common"

declare global {
	interface Window {
		skinRoot: URL
	}
}

/** Retourne un skin chargé. */
export function styleSheet(cssPathInSkin: string): Promise<CSSStyleSheet> {
	const url = new URL(cssPathInSkin, window.skinRoot).href
	let promise = styleMap.get(url)
	if (!promise) {
		promise = fetchStyleSheet(url)
		styleMap.set(url, promise)
	}
	return promise
}

export function styleSheets(...cssPathInSkin: string[]): Promise<CSSStyleSheet[]> {
	return Promise.all(cssPathInSkin.map((css) => styleSheet(css)))
}

async function fetchStyleSheet(url: string) {
	const css = await (await fetch(url)).text()
	const styleSheet = new CSSStyleSheet() //new CSSStyleSheet({baseURL: window.skinRoot}) attente https://issues.chromium.org/issues/40207149
	//Contournement bug https://issues.chromium.org/issues/40207149
	styleSheet.replaceSync(css.replaceAll('url("./', 'url("' + window.skinRoot))
	return styleSheet
}
const styleMap = new Map<string, Promise<CSSStyleSheet>>()

let parsed_qs:Dict<any> = null;
export function qs():Dict<any> {
	if (parsed_qs == null) {
		parsed_qs = {}
		if(window.location.search == "") return parsed_qs
		const it = window.location.search.matchAll(/[?&]+([^=&]+)=?([^&]*)/gi);
		let match = null;
		while (match = it.next()) {
			if(match.done) break
			if(!match.value) continue
			const key = decodeURIComponent(match.value[1])
			let value = match.value.length > 2 ? decodeURIComponent(match.value[2]) : ""
			if(value[0] == '[' || value[0] == '{' || value == "true" || value == "false" || value == "null"){
				try{
					value = JSON.parse(value)
				}
				catch (e){
					//No op
				}
			}
			if(parsed_qs[key]) parsed_qs[key] = parsed_qs[key] instanceof Array ? parsed_qs[key].push(value) : [parsed_qs[key], value]
			else parsed_qs[key] = value
		}
	}
	return parsed_qs;
}
export function updateHistory(params:Dict<any>, opt:{mergeQS?:boolean, replaceHist?:boolean}){
	if(opt?.mergeQS) qs()
	else parsed_qs = {}
	for(const key in params){
		if(params[key] instanceof Array || params[key] instanceof Object || params[key] instanceof Boolean) parsed_qs[key] = JSON.stringify(params[key])
		else parsed_qs[key] = params[key]
	}
	let search = ""
	for(const key in parsed_qs){
		search += search == "" ? "?" : "&"
		search += `${encodeURIComponent(key)}=${encodeURIComponent(parsed_qs[key])}`
	}
	const url = `${window.location.origin}${location.pathname}${search}${window.location.hash}`
	if(opt?.replaceHist) history.replaceState(parsed_qs, "", url)
	else history.pushState(parsed_qs, "", url)
}