import {JSX} from "wfr/commons/jsx"
import {ENavLanguages, L10nLabels} from "./api";

export const	labels:L10nLabels = {
	[ENavLanguages.fr]: {
		l10n_fr: "Français",
		l10n_en: "Anglais",
		l10n_es: "Espagnol",
		l10n_ar: "Arabe",
		l10n_pt: "Portugais",
		l10n_it: "Italien",
		l10n_de: "Allemand",
		l10n_el: "Grecque",
		l10n_nl: "Néerlandais",
		l10n: "Langue",
		env_linux: "Linux",
		env_windows: "Windows",
		env_mac: "MacOS",
		env: "Environnement",
		reset: "Réinitialiser",
		open:"Ouvrir",
		close:"Fermer",
		download:"Télécharger",
		doc:"Documentation",
		altLogo:"Logo du produit",
		alpha:"Alpha",
		beta:"Beta",
		rc:"Release Candidate",
		final:"Finale"
	},
	[ENavLanguages.en]: {
		l10n_fr: "French",
		l10n_en: "English",
		l10n_es: "Spanish",
		l10n_ar: "Arabic",
		l10n_it: "Italian",
		l10n_pt: "Portuguese",
		l10n_de: "German",
		l10n_el: "Greek",
		l10n_nl: "Dutch",
		l10n: "Language",
		env_lin: "Linux",
		env_win: "Windows",
		env_mac: "MacOS",
		env: "Operating system",
		reset: "Reset",
		open:"Open",
		close:"Close",
		download:"Download",
		doc:"Documentation",
		altLogo:"Product logo",
		alpha:"Alpha",
		beta:"Beta",
		rc:"Release Candidate",
		final:"Final"
	}
}

/**
 * desescape une string html
 */
export function parseHtml(html: string) {
	let element =  new DOMParser().parseFromString(html, "text/html").documentElement
	if(element.children[1].children.length == 0) element = new DOMParser().parseFromString(element.children[1].textContent, "text/html").documentElement
	return <div>{Array.from(element.children[1].children).map(el=>{return el})}</div>
}


export function sc$(id:string){return document.getElementById(id)}

export function formatDate(date:any){
	return new Date(date).toLocaleDateString("fr-FR")
}