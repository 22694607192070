import {ISearchPanel, ISearchPropsProvider, ISearchPropsRenderer, ISearchResultBuilder, ISvcSearch, JHit, JSearchResults} from "./searchapi";
import {styleSheet} from "../commons/core";
import {Dict} from "wfr/commons/common";


export class SvcESSearch<T> implements ISvcSearch<T> {
	svcUrl: string

	cache: any = {}

	constructor(url?:string) {
		this.svcUrl = url;
	}

	search(code: string, props?: any): Promise<JSearchResults<T>> {
		return new Promise((resolve, reject) => {
			const props_str = JSON.stringify(props||{})
			if (this.cache[code]?.[props_str]) resolve(this.cache[code][props_str])
			else {
				fetch(`${this.svcUrl}?${new URLSearchParams({"param": code}).toString()}`, {method:"POST", body: props_str})
					.then(resp => resp.json())
					.then(result => {
						const searchResults: JSearchResults<T> = {results: result.hits.hits, agg:{}}
						if (result.hits?.total?.relation === "eq") searchResults["size"] = result.hits.total.value
						if ("aggregations" in result) searchResults.agg = result.aggregations.global || result.aggregations
						if (!(code in this.cache)) this.cache[code] = {}
						this.cache[code][props_str] = searchResults
						resolve(searchResults)
					})
					.catch((error) => {
						reject(error)
					})
			}
		});

	}
}

export class SimpleEsResultBuilder implements ISearchResultBuilder{
  urltreeurl:string
	resultsHook(results: JSearchResults<JHit>): JSearchResults<JHit> {
		return results
	}
	beforeResults(results: JSearchResults<JHit>): string {
		if(results.size === 0) return "￼Aucun résultats￼"
	}
	afterResults(results: JSearchResults<JHit>): string {
		return ""
	}
	buildHtml(result: any): string {
        return `
<div class="result ${result._source.processing||""}">
	<a class="result" href="${this.urltreeurl}${result._source.path}">
		<span>${result._source.title||result._source.path.split("/").pop()}</span>
	</a>
</div>`;
    }
		setUrltreeurl(url:string):void{
			this.urltreeurl = url
		}
}

export class SearchPanel extends HTMLElement implements ISearchPanel<JHit> {
	svc: ISvcSearch<JHit>
	searchCode:string
	resultBuilder:ISearchResultBuilder
	propsProvider:ISearchPropsProvider[] = []
	resultElt:HTMLElement

	constructor() {
		super();
		this.resultElt = this.querySelector(".results");
		this.searchCode = this.getAttribute("code");
	}

	refreshProps(): void {
        throw new Error("Method not implemented.");
    }

	setSvc(svc: ISvcSearch<JHit>): void {
		this.svc = svc
	}
	setResultBuilder(resultBuilder: ISearchResultBuilder): void {
		this.resultBuilder = resultBuilder
	}
	registerPropsProvider(propProvider: ISearchPropsProvider): void {
		this.propsProvider.push(propProvider)
	}

	init(props: any): void {
		throw new Error("Method not implemented.");
	}
	search(): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			let props = {}
			this.propsProvider.forEach(propProvider=>props = propProvider.mergeProps(props))
			let results:JSearchResults<JHit> = await this.svc.search(this.searchCode, props);
			results = this.resultBuilder.resultsHook(results);
			let resultsHtml = this.resultBuilder.beforeResults(results);
			results.results?.forEach(result=>resultsHtml += this.resultBuilder.buildHtml(result));
			resultsHtml += this.resultBuilder.afterResults(results);
			this.resultElt.innerHTML = resultsHtml;
		});
	}
}

export class SearchComponentBase extends HTMLElement{
	panel:ISearchPanel<JHit>

	constructor() {
		super();
	}

	getPanel():ISearchPanel<JHit>{
		let node = this.parentElement;
		while (node.nodeName !== "SEARCH-PANEL") node = node.parentElement
		this.panel = node as unknown as ISearchPanel<JHit>;
		return this.panel
	}
}



export class SearchTextInput extends SearchComponentBase implements ISearchPropsProvider{
  input:HTMLInputElement
	code:string
	constructor() {
		super();
		this.code = this.hasAttribute("code") ? this.getAttribute("code") : "text";
		this.getPanel().registerPropsProvider(this);
	}

	mergeProps(props:any) {
		if(!props) props = {}
		if(this.code in props) delete props[this.code]
		if(this.input.value !== "") props[this.code] = this.input.value
		return props;
	}

	async connectedCallback(){
		this.attachShadow({ mode: 'open' });
		this.shadowRoot!.adoptedStyleSheets.push(await styleSheet("./css/components.css"));
		this.shadowRoot!.innerHTML = `<input placeholder="${this.getAttribute("placeholder")||""}"></input>`;

		this.input = this.shadowRoot!.children[0] as HTMLInputElement
		this.input.addEventListener("keyup", e=>{if(e.key === "Enter") this.getPanel().search()})
	}

}

export class SearchButton extends SearchComponentBase{
	label:string
	button:HTMLButtonElement

	constructor() {
		super();
		this.label = this.getAttribute("label")
	}

	async connectedCallback(){
		this.attachShadow({ mode: 'open' });
		this.shadowRoot!.adoptedStyleSheets.push(await styleSheet("./css/components.css"));
		this.shadowRoot!.innerHTML = `<button title="${this.label}"><span>${this.label}</span></button>`;
		this.shadowRoot.querySelector("button").addEventListener("click", e=>this.getPanel().search());
	}
	click():void{
		this.getPanel().search()
	}
}

export class SearchSelectFilter extends SearchComponentBase implements ISearchPropsProvider, ISearchPropsRenderer{
	code:string
	map:any
	select:HTMLSelectElement

	async connectedCallback(){
		this.attachShadow({ mode: 'open' });
		this.shadowRoot!.adoptedStyleSheets.push(await styleSheet("./css/components.css"));
		this.code = this.getAttribute("code");
		this.getPanel().registerPropsProvider(this);
	}
	renderProps(props: any): void {
		if(this.code in props) this.select.value = props[this.code]
	}
	mergeProps(props: any) {
		if(this.select.value) props[this.code] = this.select.value;
		return props;
	}
	setMap(map:any){
		this.map = map;

		let select = "<select>";
		for (const key in this.map) select += `<option value="${key}">${this.map[key]}</option>`;
		select += `</select>`;
		this.shadowRoot!.innerHTML = select;
		this.select = this.shadowRoot.querySelector("select") as HTMLSelectElement
		this.select.addEventListener("change", e=>this.getPanel().search());
	}
}

export class SearchCheckboxFilter extends SearchComponentBase implements ISearchPropsProvider, ISearchPropsRenderer{
	code:string
	value:string
	label:string
	checked:boolean
	constructor() {
		super();
		this.code = this.getAttribute("code");
		this.label = this.getAttribute("label");
		this.value = this.getAttribute("value");
		this.checked = this.getAttribute("checked") === "true";


	}
	async connectedCallbatck(){
		this.attachShadow({ mode: 'open' });
		this.shadowRoot!.adoptedStyleSheets.push(await styleSheet("./css/components.css"));
		this.shadowRoot!.innerHTML = `<label><span>${this.label}</span><input type="checkbox" checked="${this.checked}"></label>`;
		this.shadowRoot.querySelector("input").addEventListener("change", (e:InputEvent)=>{
			this.checked = (e.target as HTMLInputElement).checked;
			this.setAttribute("checked", String(this.checked));
			this.getPanel().search();
		});
		this.getPanel().registerPropsProvider(this);
	}
	renderProps(props: any): void {
		if(this.code in props && props[this.code] === this.value){
			this.shadowRoot.querySelector("input").checked = true;
			this.checked = true;
		}
		else{
			this.shadowRoot.querySelector("input").checked = false;
			this.checked = false;
		}
		this.setAttribute("checked", String(this.checked));
	}
	mergeProps(props: any) {
		if(this.checked){
			if(!(this.code in props)) props[this.code] = [];
			props[this.code].push(this.value);
		}
		return props;
	}

}

customElements.define('search-panel', SearchPanel)
customElements.define('search-text-input', SearchTextInput)
customElements.define("search-button", SearchButton)
customElements.define("search-select-filter", SearchSelectFilter)
customElements.define("search-checkbox-filter", SearchCheckboxFilter)