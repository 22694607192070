import {qs, styleSheet, updateHistory} from "wfr/commons/core"
import {JSX} from "wfr/commons/jsx"
import {ENavLanguages, L10nLabels} from "./api"
import {labels as commonLabels} from "./commons";

export {lang, LanguageSelector, ScSoftwareComponent}

const labels:L10nLabels = {
	[ENavLanguages.fr]:{
		language:"Français",
		changeLanguage:"Changer la langue d'affichage",
		closeLangMenu:"Fermer le menu des langues"
	},
	[ENavLanguages.en]:{
		language:"English",
		changeLanguage:"Change browsing language",
		closeLangMenu:"Close language menu"
	}
}

let lang:ENavLanguages

abstract class ScSoftwareComponent extends HTMLElement{
	/**
	 * Gestion CSS
	 * Gestion multilingue
	 */
	protected current_lang:ENavLanguages
	protected labels:L10nLabels
	async connectedCallback() {
		this.attachShadow({mode: 'open'})
		this.shadowRoot!.adoptedStyleSheets.push(await styleSheet("./css/components.css"))
	}
	rebuild():boolean{
		/**
		 * return true si un rebuild est nécessaire
		 */
		if(this.current_lang == lang) return false
		if(this.shadowRoot) this.shadowRoot.innerHTML = ""
		this.current_lang = lang
		return true
	}
	protected s(key:string, ...args:any):string{
		if(key in commonLabels[lang]){
			if(typeof commonLabels[lang][key] == "function") return (commonLabels[lang][key] as (...args:any)=>string)(...args)
			else return commonLabels[lang][key] as string
		}

		if(typeof this.labels[lang][key] == "function") return (this.labels[lang][key] as (...args:any)=>string)(...args)
		else return this.labels[lang][key] as string
	}
}

class LanguageSelector extends ScSoftwareComponent{
	labels = labels
	langPathMatcher = window.location.pathname.match(/(.*\/)(en|fr)\/[^\/]*/)
	constructor() {
		super();
		if(this.langPathMatcher) lang = this.langPathMatcher[2] as ENavLanguages
		else{
			const state_qs = qs()
			const foundLang:string = state_qs["navLang"] || localStorage.getItem("navLang") || navigator.language.substring(0, 2);
			lang = (Object.keys(ENavLanguages).includes(foundLang) ? foundLang : "fr") as ENavLanguages
			localStorage.setItem("navLang", lang)
			if(state_qs["navLang"]) {
				delete state_qs["navLang"]
				updateHistory(state_qs, {"replaceHist":true})
			}

		}
	}

	async connectedCallback(){
		await super.connectedCallback()
		this.rebuild()

		const ls = this;

		const keys = (event:KeyboardEvent)=>{
			const button = ls.shadowRoot.querySelector("button")
			if(button.classList.contains("closed")) return

			const selected = ls.shadowRoot.querySelector("li.selected")

			switch (event.code){
			case "ArrowDown":
				selected?.classList.remove("selected")
				selected?.nextElementSibling?.classList.add("selected")
				if(!selected) ls.shadowRoot.querySelector("li").classList.add("selected")
				break
			case "ArrowUp":
				selected?.classList.remove("selected")
				selected?.previousElementSibling?.classList.add("selected")
				if(!selected) ls.shadowRoot.querySelector("li:last-child").classList.add("selected")
				break
			case "Enter":
				if(selected) selected.querySelector("button").click()
				else button.click()
				break
			case "Escape":
				button.click()
				break
			}
		}

		window.addEventListener("keyup", keys)
	}

	rebuild(){
		if(!super.rebuild()) return false;

		this.shadowRoot.innerHTML = ""
		this.shadowRoot.append(<button class="closed" title={this.s("changeLanguage")} onclick={this.open}>
			<span>{this.s("language")}</span>
		</button>)
		return true;
	}

	disableRedirect(){
		this.langPathMatcher = null;
	}

	open(event:MouseEvent){
		const ls = (this.getRootNode() as ShadowRoot).host as LanguageSelector
		this.classList.remove("closed")
		this.classList.add("opened")
		this.onclick = ls.close;
		this.setAttribute("title", ls.s("closeLangMenu"));
		ls.shadowRoot.append(<ul>
			{Object.keys(labels).map((k)=>{
				const key = k as unknown as ENavLanguages
				if(key !== lang)
					return <li>
						<button class="option" title={labels[key]["language"]} data-key={key} onclick={ls.validateOption}>
							<span>{labels[key]["language"]}</span>
						</button>
					</li>
			})}
		</ul>)
	}

	close(event:Event){
		const ls = (this.getRootNode() as ShadowRoot).host as LanguageSelector
		ls.shadowRoot.querySelector("ul")?.remove()
		this.classList.remove("opened")
		this.classList.add("closed")
		this.onclick = ls.open;
		this.setAttribute("title", ls.s("changeLanguage"))
	}

	validateOption(event:Event){
		const ls = (this.getRootNode() as ShadowRoot).host as LanguageSelector
		const validated_lang = this.getAttribute("data-key") as unknown as ENavLanguages
		if(validated_lang != lang){
			lang = validated_lang
			localStorage.setItem("navLang", lang)
			const languageChangeEvent = new CustomEvent("language-change", {detail:{"lang":lang}})
			ls.dispatchEvent(languageChangeEvent)
		}
		if(ls.langPathMatcher) window.location.assign(ls.langPathMatcher[1] + lang)
		else ls.rebuild()
	}
}

customElements.define('language-selector', LanguageSelector)